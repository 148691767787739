body {
    background-color: #fff;
}

.root {
    width: fit-content;
    height: 100vh;
    margin: auto;
    position: relative;
}

.battlefield-bg {
    height: 100%;
}

.battlefield-layout {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.evolutions, .substitutes, .trainer, .active, .attack, .actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.evolutions {
    height: 13%;
}

.evolutions__img {
    width: 70px;
}

.battlefield {
    display: flex;
    height: 76%;
}

.substitutes {
    height: 11%;
}

.trainer {
    width: 15%;
}

.trainer__img {
    width: 80px;
}

.active,
.actions {
    width: 23%;
}

.active__img {
    width: 110px;
    margin-top: -30px;
}

.attack {
    width: 24%;
}
.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
    padding-left: 15px;
}

.btn {
    appearance: none;
    width: 100%;
    padding: 13px;
    border: 0;
    border-radius: 6px;
    margin: 15px 0;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 500ms ease, transform 500ms ease;
}

.btn:not([disabled]):hover {
    transform: scale(1.1);
}

.upgrade {
    background-color: rgb(230, 38, 38);
}

.upgrade[disabled] {
    background-color: rgba(243, 61, 61, 0.3);
}

.downgrade {
    background-color: rgb(39, 65, 218);
}

.downgrade[disabled] {
    background-color: rgba(65, 90, 231, 0.3);
}

.escape {
    background-color: rgb(23, 156, 11);
}

.escape[disabled] {
    background-color: rgba(51, 182, 39, 0.3);
}

.btn__picto {
    background: rgba(255, 255, 255, 0.25);
    display: inline-block;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    margin-left: 10px;
    font-size: 1.2em;
    line-height: 23px;
    text-align: center;
}
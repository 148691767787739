.root {
    appearance: none;
    background: none;
    padding: 0;
    border: 0;
}

.root:not([disabled]) {
    cursor: pointer;
}

.figure {
    min-width: 80px;
    margin: 0 10px;
}

.image {
    display: block;
    width: 40px;
    margin: 0 auto 5px;
    transition: transform 300ms ease;
}

.root:not([disabled]):hover .image {
    transform: scale(1.15);
}

.name {
    display: block;
    margin: auto;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    transition: color 300ms ease, text-shadow 300ms ease;
}

.root:not([disabled]):hover .name {
    color: #cc0000;
    text-shadow: none;
}